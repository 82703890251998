<template>
    <div class="wrap">
        <img class="hero-logo" src="/static/img/hero-logo.png" />

        <div class="flash"></div>

        <div class="main-layout">
            <div class="content">
                <slot></slot>
            </div>
        </div>
        <div class="main-buttons">
            <a href="/static/pdf/actievoorwaarden.pdf" class="info" target="_blank">&nbsp;</a>
            <a href="#" :class="{ sound: true, muted: muted }" @click.prevent="toggleSound">&nbsp;</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        data()
        {
            return {
                muted: false
            };
        },
        created()
        {
            window.createjs.Sound.registerSound( '/static/audio/bite.mp3', 'bite' );
            window.createjs.Sound.registerSound( '/static/audio/tune.mp3', 'tune' );
            window.createjs.Sound.registerSound( '/static/audio/game-lose.mp3', 'game-lose' );
            window.createjs.Sound.registerSound( '/static/audio/game-success.mp3', 'game-success' );
            window.createjs.Sound.registerSound( '/static/audio/scored1.mp3', 'scored1' );
            window.createjs.Sound.registerSound( '/static/audio/scored2.mp3', 'scored2' );
            window.createjs.Sound.registerSound( '/static/audio/cassissound.mp3', 'cassissound' );
        },
        methods: {
            toggleSound()
            {
                window.createjs.Sound.muted = !window.createjs.Sound.muted;
                this.muted = window.createjs.Sound.muted;
            }
        }
    };
</script>
