export default {
    namespaced: true,

    state: {
        score: 0,
        highscore: {
            name: null,
            score: 0
        }
    },

    getters: {
        score: state => state.score,
        highscore: state => state.highscore
    },

    mutations: {
        addScore: ( state, payload ) =>
        {
            state.score += payload;
        },

        setHighscore: ( state, payload ) =>
        {
            state.highscore = payload;
        },

        resetVars: state =>
        {
            state.score = 0;
        }
    },

    actions: {
        addScore: ( context, payload ) =>
        {
            context.commit( 'addScore', payload );
        },

        setHighscore: ( context, payload ) =>
        {
            context.commit( 'setHighscore', payload );
        },

        resetVars: context =>
        {
            context.commit( 'resetVars' );
        }
    }
};
