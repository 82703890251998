<template>
    <div class="landing-view new">
        <img class="top-visual" src="/static/img/landing-top-visual.png" />

        <button @click="toGame">Ssstart</button>

        <img class="koelkast" src="/static/img/koelkast-asset.png" />

        <!-- <img class="original-logo" ref="orig" src="/static/img/lekker-origineel.png" />
        <img class="visual" ref="visual" src="/static/img/landing-visual.png" /> -->

        <!-- <div class="endless">
            <div class="fridge"></div>
            <div class="bes">
                <div class="fill"></div>
            </div>
        </div> -->
    </div>
</template>

<style lang="scss">
</style>

<script>
    import gsap from 'gsap';
    import screenfull from 'screenfull';

    export default {
        name: 'Landing',

        mounted()
        {
        },

        methods: {
            toGame()
            {
                if( screenfull.isEnabled && window.innerWidth < 500 )
                {
                    screenfull.request();
                }

                window.createjs.Sound.play( 'tune', { interrupt: window.createjs.Sound.INTERRUPT_ANY, loop: -1 });
                this.$router.push( '/game' );
            },
            getAnimateIn()
            {
                this.tl = gsap.timeline({ delay: 0.3 });

                this.tl.from( '.top-visual', { scale: 0.5, autoAlpha: 0, ease: 'back.out', duration: 0.7 }, 0 );
                // this.tl.from( '.top h1', { y: 20, autoAlpha: 0 }, 0 );

                this.tl.from( '.koelkast', { autoAlpha: 0, scale: 0.5, rotation: 10, ease: 'back.out' }, 0.3 );

                this.tl.from( 'button', { autoAlpha: 0, scale: 0.5, rotation: -10, ease: 'back.out' }, 0.5 );

                return this.tl;
            },
            getAnimateOut()
            {
                if( this.tl )
                {
                    this.tl.kill();
                }

                this.tl = gsap.timeline({});

                this.tl.to( this.$el, { opacity: 0 });

                return this.tl;
            }
        }
    };
</script>
