import Vue from 'vue';
import Router from 'vue-router';

import store from '@/app/store';

// import Landing from '@/app/pages/Landing';
import NewLanding from '@/app/pages/NewLanding';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: NewLanding
        },

        {
            path: '/share/:id',
            name: 'SharePath',
            beforeEnter: ( to, from, next ) =>
            {
                if( window._rootData.highscore )
                {
                    store.dispatch( 'game/setHighscore', window._rootData.highscore );
                }

                next( '/' );
            }
        },

        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            }
        },

        {
            path: '/naw',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            }
        },

        {
            path: '/bedankt',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/app/pages/Thanks' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
